//
//	SECTION - OPEN SOURCE
// ------------------------------------------------------
.open-source {
    padding: 4em 0;

    > .row {
        padding: 6em 3.2em 8em;

        @media (max-width: 62em) {
            padding: 2em;
        }

        .row {
            padding: 2em 0 0 1em;

            @media (max-width: 48em) {
                padding: 0 2em;
            }

            .col-md-6 {
                padding: 1.5em 1em 0 0;
            }
        }
    }

    .main-img {
        display: block;
        margin: auto;

        @media (min-width: 137em) {
            max-width: 55em;
        }

        @media (max-width: 62em) {
            margin-bottom: 2em;
            max-width: 60%;
        }
    }

    p {
        max-width: 17em;
    }

    @media (max-width: 62em) {
        h3 {
            text-align: center;
        }
    }
}
