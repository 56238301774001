@use '../variables' as *;
@use '../mixins';

//
//	ARTICLE - ALL ARTICLES - PAGINATION
// ----------------------------------------------------
.aldryn-newsblog-pagination {
    background: $white;
    font-weight: bold;
    margin-top: 2em;
    padding: 10px 16px;
    text-transform: uppercase;

    ul {
        display: flex;
        justify-content: space-between;
        margin: 0;

        li {
            color: $grey-menu;

            a {
                color: $grey-menu;
                text-decoration: none;
                transition: all 0.1s ease-in;

                &:hover {
                    color: $blue-hover;
                    transition: all 0.1s ease-in;
                }

                &.next-page {
                    &::after {
                        @include mixins.arrow();
                    }
                }

                &.prev-page {
                    &::before {
                        @include mixins.arrow();

                        position: relative;
                        top: 6px;
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }
}
