@use '../variables' as *;
@use '../mixins';

//
//	SECTION - CAROUSEL
// ------------------------------------------------------
.carousel {
    @include mixins.defaultflex(center, space-around);

    flex-wrap: wrap;
    margin: 8.6em 0;

    @media (max-width: 991px) {
        justify-content: space-between;
    }

    @media (max-width: 767px) {
        margin: 3em 0;
    }

    .d-block {
        display: inline !important; //Because i need to overwrite !importnat from bootstrap 4
    }

    .w-100 {
        width: 250px !important; //Because i need to overwrite !importnat from bootstrap 4
    }

    .d-none {
        display: block !important; //Because i need to overwrite !importnat from bootstrap 4
    }

    &:nth-child(1) {
        order: 1;
    }

    &:nth-child(2) {
        order: 2;
    }

    &:nth-child(3) {
        order: 4;
    }

    &:nth-child(4) {
        order: 3;
    }

    .carousel-inner {
        margin-bottom: 30px;
    }

    .carousel-item.active {
        @include mixins.defaultflex(center, space-between);
    }

    .carousel-item {
        @media (max-width: 767px) {
            flex-direction: column;
        }

        img {
            margin: 0 0 3em 10%;

            @media (max-width: 991px) {
                margin: 0 3em 3em auto;
            }

            @media (max-width: 767px) {
                margin: 0 auto 3em;
            }
        }

        &::after {
            background: url('../img/reference-with-shadow.svg'); //center center no-repeat;
            content: '';
            height: 30px;
            left: calc(10% + 25px);
            position: absolute;
            top: 270px;
            width: 200px;

            @media (max-width: 991px) {
                left: 25px;
            }

            @media (max-width: 767px) {
                left: calc(50% - 100px);
            }
        }
    }

    .carousel-caption {
        bottom: 0;
        float: right;
        left: 0;
        margin-top: 2em;
        max-width: 470px;
        padding: 0 15px;
        position: relative;
        right: 0;
        text-align: left;

        @media (max-width: 991px) and (min-width: 768px) {
            max-width: calc(100% - 250px - 3em);
        }

        @media (max-width: 767px) {
            margin: 2em auto;
        }

        h3 {
            color: $blue-hover;
            font-size: 18px;
            line-height: 1.2;
            margin-bottom: 10px;
        }

        h4 {
            color: $grey;
            font-size: 18px;
            line-height: 1.5;
        }

        p {
            margin-bottom: 0;
        }
    }

    .carousel-indicators {
        bottom: -5px;
        flex-wrap: wrap;
        left: 0;
        margin: 0;
        right: 0;

        li {
            background-color: $header-text;
            border-radius: 50%;
            cursor: pointer;
            display: inline;
            flex-shrink: 0;
            height: 10px;
            margin: 1em;
            width: 10px;
        }
    }

    .carousel-indicators .active {
        background-color: $blue-hover;
    }

    a[class^='carousel-control-'] {
        display: inline-block;
        height: 36px;
        left: auto;
        margin: 0 25px;
        opacity: 1;
        position: relative;
        width: 36px;
        z-index: 100;

        @media (max-width: 767px) {
            display: none;
        }
    }

    .carousel-control-prev {
        background: url('../img/arrow-carousel.svg') no-repeat;

        &:hover {
            background: url('../img/arrow-carousel-hover.svg') no-repeat;
        }
    }

    .carousel-control-next {
        background: url('../img/arrow-carousel.svg') no-repeat;
        transform: rotate(180deg);

        &:hover {
            background: url('../img/arrow-carousel-hover.svg') no-repeat;
        }
    }

    .carousel-control-prev-icon,
    .carousel-control-next-icon {
        display: none;
    }
}

.carousel.none-img {
    .d-block {
        display: none !important; //Because i need to overwrite !importnat from bootstrap 4
    }

    .carousel-caption {
        float: none;
        margin: auto;
        max-width: 50%;

        @media (max-width: 767px) {
            max-width: 80%;
        }
    }

    .carousel-item {
        min-height: 350px;

        &::after {
            content: none;
        }
    }

    .carousel-item.active {
        display: block;
    }

    h3 {
        color: $blue-hover;
        font-size: 22px;
        margin-bottom: 20px;
    }

    p {
        margin-bottom: 20px;
    }
}
