@use './variables' as *;

//
// MAIN - BUTTONS
// ------------------------------------------------------
.btn-primary {
    background-color: $blue-hover;
    border-color: $btn-border;
    color: $white;
    font-weight: 700;
    text-decoration: none;

    &:focus,
    &:hover {
        background-color: $btn-hover;
    }

    &:active {
        background-color: $blue-hover;
    }

    &:visited {
        color: $white;
    }
}

.btn-group {
    .sr-only {
        border: 0;
        clip: rect(0, 0, 0, 0);
        clip-path: inset(50%);
        height: 1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    }

    @media (max-width: 992px) {
        margin-top: 1em;
    }

    a {
        color: $white;
        text-decoration: none;
    }

    .dropdown-item {
        color: $dark-grey;
        line-height: 1.5em;
        padding: 0.25em 2em 0.25em 1em;
    }

    .dropdown-menu.show {
        transform: translate3d(0, 42px, 0) !important;
    }

    .arrow {
        background-image: url('../img/arrow-dark.svg');
        height: 24px;
        position: absolute;
        right: 5px;
        width: 24px;
    }

    .btn-primary {
        border-color: $btn-border;
        font-weight: 700;
        padding: 0.5em 1em;
    }

    .btn:active,
    .btn:focus,
    .btn.active {
        background-image: none;
        border: 1px solid $btn-border;
        box-shadow: none;
        outline: 0;
    }

    .dropdown-toggle-split {
        width: 2em;
    }

    .dropdown-toggle::after {
        border: 0;
        bottom: 0;
        content: url('../img/arrow_down.svg');
        left: 0.5em;
        margin: auto;
        position: absolute;
        right: 0;
        top: 1em;
    }
}

a.btn-primary {
    &:focus {
        background-color: $blue-hover;
    }
}

.btn-arrow {
    padding-right: 2em;

    &::after {
        content: url('../img/arrow-light.svg');
        position: absolute;
    }
}
