@use '../variables' as *;

//
//	RESPONSE - max-width: 574px - max-width: 351px
// ----------------------------------------------------
@media (max-width: 574px) {
    .omnia {
        .features__table .row {
            padding: 0.5em 0;

            p {
                line-height: 1.5em;
                text-align: left;
            }
        }
    }

    .about-us {
        padding: 3em 0;

        &-block {
            width: 100%;

            &__txt--contacts ul {
                padding-left: 15px;
                word-wrap: break-word;
            }
        }
    }

    .buy-distributors__item,
    .buy-footer__logos .logo-link {
        border-bottom: 1px solid $header-text;
        margin: 0;
        width: 100%;
    }

    .buy-links {
        border-top: 0;
        margin: 0;
    }

    .carousel-control {
        padding: 0;
    }

    .buy-distributors__item {
        figure {
            margin: 1rem 0;
        }
    }

    .buy-footer__logos .logo-link {
        figure {
            margin: 1rem 0;
        }
    }
}
