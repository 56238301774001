@use '../variables' as *;

//
//	SECTION - RESPONSIVE TABLES
// ------------------------------------------------------
.container-table {
    margin: 8.75em auto 0;

    @media (max-width: 69em) {
        margin: 4.75em auto 0;
    }

    @media (max-width: 48em) {
        padding: 0;
    }

    p {
        margin: 0;
    }

    table {
        text-align: center;
        width: 100%;

        @media (max-width: 69em) {
            width: 100%;
        }

        thead {
            th {
                background: $dark-black;
                border: 1px solid $dark-black;
                color: $white;
                padding: 0.6em 0.3em;
                vertical-align: middle;

                p {
                    &:last-of-type {
                        color: $bg;
                        font-weight: 400;
                    }
                }

                &:first-of-type {
                    background: $white;
                    border: 1px solid $white;
                    transform: scaleY(1.1);
                }
            }
        }

        td {
            border: 1px solid $table-border;
            border-bottom: 1px solid $dark-black;
            border-top: 1px solid $dark-black;
            vertical-align: inherit;

            @media (max-width: 48em) {
                height: 5em;
                line-height: 1.2;
                padding: 0 0.66em;
            }
        }

        figure {
            display: inline;
            margin: 0;

            img {
                height: 1em;
            }
        }

        img {
            height: 1em;
        }
    }
}

.table-responsive {
    @media (max-width: 48em) {
        left: -1px;
        position: relative;
    }

    table {
        position: relative;

        tbody th {
            &:first-of-type {
                left: 0;
                position: sticky;
                z-index: 9;
            }
        }

        thead th {
            &:first-of-type {
                left: 0;
                position: sticky;
                top: 0;
                z-index: 9;
            }
        }
    }
}
