@use '../variables' as *;

//
//	PAGE - ABOUT US
// ------------------------------------------------------
.year-block {
    background: $white;
    padding-bottom: 1em;

    &__table {
        padding: 2em 1.5em 0;
    }

    &__img {
        img {
            width: 100%;
        }
    }
}

.row--year-table {
    margin: 0 15px;
}

.table-cell {
    font-size: 1.5em;
    font-weight: bold;
    line-height: 0.8em;

    &--year {
        border-right: 2px solid $header-text;
    }

    &--q {
        padding: 0 0.5em;
    }

    &--info {
        border-left: 2px solid $header-text;
        font-size: 1.25em;
        font-weight: 300;

        ul {
            position: relative;
            top: -7px;

            li {
                margin-bottom: 0.5em;
            }
        }
    }
}

.col--info {
    margin-left: -15px;
}

.about {
    margin-bottom: 6em;
}
