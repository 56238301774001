@use '../variables' as *;

//
//	RESPONSE - max-width: 767px - min-width: 575px
// ------------------------------------------------------
@media (max-width: 767px) {
    .block-table__c-title p,
    .block-table__c-content p {
        padding: 0 1em;
    }

    .block-table__header .table-title {
        padding: 2em;
    }

    .articles-all .latest-title {
        padding: 3em 0 1.5em;
        width: 100%;
    }

    .articles-all .aldryn-newsblog-article:hover {
        margin-top: 0;
    }

    .aldryn-newsblog-detail .aldryn-newsblog-header {
        margin: 0 auto;
        padding: 1em 0.3em;
        width: 100%;
    }

    .aldryn-newsblog-detail .lead {
        padding: 1em;
    }

    .subscribe {
        padding: 1.75em 0;

        &-inner {
            display: block;
        }

        &-text {
            margin-bottom: 0.5em;
        }
    }

    .container--article {
        padding-bottom: 4em;
    }

    .omnia {
        .intro__img {
            img {
                width: 100%;
            }
        }
    }

    .matrix-block {
        padding: 1em 1em 3em;

        &__btn {
            bottom: 1em;

            a {
                padding: 0.25rem 0.8rem;
                visibility: visible;
            }
        }
    }

    .feature-matrix--4 {
        .matrix-block {
            border-bottom: 2px solid $header-text;
            padding: 1em 1em 3em;

            &__btn {
                bottom: 1em;

                a {
                    border-color: $blue-hover;
                }
            }

            &:hover {
                .matrix-block__btn a {
                    border-color: $white;
                }
            }
        }
    }

    .buy-distributors__item {
        width: 50%;
    }

    .buy-footer__logos .logo-link {
        width: 40%;
    }
}
