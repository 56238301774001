@use '../variables' as *;

//
// UNDERMENU
// ------------------------------------------------------

.bg {
    background-color: $grey-menu;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 1;
}

.bg-under {
    background-color: $grey-menu;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 1;

    @media (min-width: 992px) {
        background-color: $btn-bg;
    }
}

.undermenu,
.undermenu3 {
    font-family: 'Roboto', sans-serif;
    padding-inline-start: 0;
    padding-left: 10px;
    @media (min-width: 992px) {
        padding-left: 43px;
    }

    > li {
        display: block;
        font-size: 14px;
        list-style-type: none;
        text-transform: uppercase;
        transition: all 0.2s ease-in;

        @media (min-width: 992px) {
            display: inline-block;
            margin-left: 32px;

            &:hover {
                box-shadow: inset 1px -3px 0 -2px $blue-hover;
            }
        }

        a {
            color: $header-text;
            display: block;
            font-weight: normal;
            padding: 5px 0;
            text-decoration: none;

            @media (min-width: 992px) {
                padding: 20px 0;
            }
        }

        &.active {
            @media (min-width: 992px) {
                box-shadow: inset 1px -8px 0 -2px $btn-bg;
            }

            > a {
                font-weight: $bold;
            }
        }
    }
}

.undermenu3 {
    padding-left: 53px;

    @media (min-width: 992px) {
        padding-left: 43px;
    }

    li {
        .active_link {
            font-weight: $bold;

            @media (min-width: 992px) {
                box-shadow: inset 1px -8px 0 -2px $dark-grey;
            }
        }
    }
}
