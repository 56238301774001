@use '../variables' as *;

//
//	RESPONSE - max-width: 991px - min-width: 768px
// ------------------------------------------------------
@media (max-width: 991px) {
    nav {
        background: $grey-menu;
        left: 0;
        max-height: 0;
        overflow: hidden;
        position: absolute;
        top: 73px;
        transition: all 0.6s ease-in-out;
        width: 100%;
        z-index: 100;
    }

    .article-img {
        img {
            max-height: initial;
        }
    }

    .modules-item__img {
        img {
            float: left;
            margin: 2em 0 0;
        }
    }

    .header__left-side {
        display: block;
        text-align: center;
        width: 100%;
    }

    .search-form {
        display: inline-block;
        margin: 1.45em 0;
    }

    .language-chooser {
        padding: 0;
        text-align: left;

        .lang {
            border-bottom: 0;
            margin-left: 1.5em;
            padding: 0 0 2em;

            &:hover {
                border-bottom: 0;
            }
        }
    }

    .bg {
        background: transparent;
        height: auto;
        position: inherit;
    }

    .intro {
        padding: 0 0 2em;

        &__img img {
            margin-bottom: 4em;
            width: 100%;
        }

        &__img-mb-0 img {
            margin-bottom: 0;
        }

        &__text {
            display: block;

            &__link {
                margin: 1em 0 0;
                width: 60%;
            }

            &__subtitle {
                margin: 0;
            }
        }
    }

    .pref {
        padding: 4em 0 0;

        h3 {
            font-size: 1.6em;
        }

        &-item {
            margin-bottom: 3em;
            padding: 0 15px;

            img {
                margin-bottom: 0;
                width: 100px;
            }
        }
    }

    header {
        .logo-icon {
            padding: 1.5em 0;
            text-align: center;

            img {
                z-index: 0;
            }
        }
    }

    .turris_adv__img {
        height: 420px;
        margin-top: 1em;
        overflow: hidden;
    }

    .modules {
        padding: 2em 0;

        &__title {
            margin: 0;
            padding: 0 15px;
        }
    }

    .parts-slider h1 {
        left: 0;
        padding: 0 15px;
        position: absolute;
        text-align: center;
        top: -400px;
        width: 100%;
    }

    .carousel__img img.center-block {
        max-height: 400px;
        max-width: 310px;
        transform: rotate(90deg);
    }

    .parts-slider {
        padding: 2em 0 0;

        &__txt {
            .carousel {
                margin-top: -3em;
            }
        }
    }

    .site-map {
        padding: 2em 0 0;

        &__item {
            margin-bottom: 2em;

            h2 {
                margin-bottom: 0.5em;
            }
        }
    }

    hr {
        margin: 0;
    }

    .turris-is-developed {
        margin-top: 1em;
    }

    .carousel-caption {
        text-align: left;
    }

    .parts-slider__txt .c-caption {
        margin: 1em 0;
        text-align: center;
    }

    .error-title {
        font-size: 3em;
        margin: 150px auto 200px;
    }

    .mp-wrapper {
        height: auto;
        max-height: inherit;
        padding-bottom: 2em;

        .intro__img {
            text-align: center !important;

            img {
                float: none;
                margin-bottom: 2em;
                width: 70%;
            }
        }
    }

    .table-cell {
        &--year {
            border-right: 0 !important;
        }
    }

    .col--info {
        margin-left: 0;
    }

    .omnia {
        .features {
            padding: 3em 0;
        }

        .intro__img {
            text-align: center;

            img {
                float: none;
                width: 80%;
            }
        }
    }
}
