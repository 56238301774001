@use '../variables' as *;
@use '../mixins';

//
//	SECTION - COLLASE
// ------------------------------------------------------
.collapse-box {
    background-color: $white;
    padding: 2.5em 4.75em 1em;

    @media (max-width: 48em) {
        padding: 2.5em 0.75em 1em;
    }

    .collapse {
        position: relative;

        &::after {
            background-color: $bg;
            content: '';
            height: 1px;
            left: 0;
            position: absolute;
            right: 0;
        }
    }

    >div:nth-last-of-type(2),
    >div:last-of-type {
        border-bottom: 0;

        &::after {
            background-color: transparent;
        }
    }

    ul {
        padding-left: 10px;

        li {
            @include mixins.defaultflex(center, flex-start);

            font-weight: 900;
            list-style-type: none;
            margin: 0.5em 0;

            &::before {
                background-image: url('../img/icon-check-mark.svg');
                background-repeat: no-repeat;
                content: '';
                display: inline-block;
                height: 3.5em;
                transform: translate(-15px, 2px);
                width: 3.5em;
            }
        }
    }

    ol {
        margin-left: -6px;
        padding-left: 0;

        li {
            border: 1px solid $bg;
            display: inline-block;
            list-style-type: none;
            margin: 0.3em;
            min-width: 37%;
            padding: 0.3em 0.5em;
        }
    }
}
