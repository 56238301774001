//
//	SECTION - STAM
// ------------------------------------------------------
.stamp {
    img {
        bottom: 5vh;
        height: 10vh;
        position: absolute;
        right: 5vh;
        width: 10vh;

        @media (max-width: 47.9375em) {
            bottom: 0;
            height: 5em;
            right: 2em;
            width: 5em;
        }

        @media (max-width: 30em) {
            right: 2em;
        }
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (min-device-pixel-ratio: 2) and (orientation: portrait) {
    .stamp {
        img {
            bottom: 5vh;
        }
    }
}
