@use '../variables' as *;

.tags {
    ul {
        margin-bottom: 2.2em;
        padding-left: 0;

        @media (max-width: 48em) {
            margin-bottom: 1.1em;
        }
    }

    li {
        display: inline-block;
        list-style-type: none;
    }

    a {
        border: 1px solid $blue-hover;
        border-radius: 4px;
        color: $blue-hover;
        display: inline-block;
        font-weight: bold;
        line-height: 1;
        margin: 5px;
        padding: 6px 8px;
        text-decoration: none;

        &:hover {
            background-color: $blue-hover;
            color: $white;
        }
    }

    span {
        display: none;
    }
}
