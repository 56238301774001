@use './variables' as *;

//
//	MAIN - BASE GLOBAL STYLES
// ----------------------------------------------------
body {
    font-family: 'Roboto', sans-serif;
    margin: 0;

    @media (min-width: 188em) {
        margin: auto;
        max-width: 187em;
    }
}

*:focus {
    border: 0;
    box-shadow: none;
    outline: none !important;
    text-decoration: none;
}

*:active {
    outline: none !important;
}

strong {
    font-weight: $bold;
}

h2 {
    font-size: 2.8em;
    margin-bottom: 0.8em;
}

h3 {
    font-size: 1.95em;
}

h5 {
    font-size: 1.44em;
}

a {
    color: inherit;
    text-decoration: underline;

    &:hover {
        color: $grey;
        text-decoration: none;
    }

    &:visited {
        color: $grey;
    }
}

.btn-primary:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-primary.dropdown-toggle:focus,
.btn-primary.focus {
    background-color: $btn-hover;
    border: 1px solid $btn-border;
    box-shadow: 0 0 0 0 $blackfullytransparent;
}

.container {
    max-width: 1280px;

    @media (max-width: 1299px) {
        padding-left: 30px;
        padding-right: 30px;
    }

    .btn-primary:not(:disabled):not(.disabled):active,
    .btn-primary:not(:disabled):not(.disabled).active,
    .show>.btn-primary.dropdown-toggle {
        background-color: $blue-hover;
        border: 1px solid $btn-border;
        color: $white;
    }

    &--article {
        padding-bottom: 6em;
    }
}

.row-header {
    p,
    a {
        color: $grey-menu;
        font-size: 1em;
        font-weight: $bold;
        line-height: 1.5em;
        margin: 0;
        text-transform: uppercase;
    }

    a {
        text-decoration: none;
    }

    h3 {
        font-size: 1.6em;
        line-height: 1.5em;
    }
}

#all {
    overflow: hidden;
}

.error-title {
    color: $dark-grey;
    font-size: 3.2em;
    font-weight: $bold;
    line-height: 1.2em;
    margin: 150px auto 300px;
    width: 70%;
}

.error {
    color: $red;
}

ul.errorlist {
    padding-left: 0;
}

.errorlist li {
    background-color: $red;
    color: $white;
    list-style-type: none;
    padding: 0.2rem 1rem;
}

.no-padding {
    padding: 0;
}

.bg-wrap {
    background: $bg;
}

.container-center {
    text-align: center;

    img {
        margin: 2em 1em;
    }
}

.show-on-mobile {
    display: block;

    @media (min-width: 992px) {
        display: none;
    }
}

.show-on-desktop {
    display: block;

    @media (max-width: 991px) {
        display: none;
    }
}

.dropdown-menu.show {
    display: inline-block;
    min-width: 100%;
    width: auto;
}

.img-center {
    max-width: 100%;
}

.img-cover {
    height: 100%;
    object-fit: cover;
    width: 100%;
}

.img-over.home-router {
    .text-inside-over {
        padding: 0 2em;
    }
}

.shadow-sm {
    box-shadow: 0 0.125em 0.25em rgba(0, 0, 0, 0.075);
}

.shadow {
    box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.15);
}

.shadow-lg {
    box-shadow: 0 1em 3em rgba(0, 0, 0, 0.175);
}

.img-center-center {
    height: 100%;
    object-fit: cover;
    width: 100%;
}

.all-center {
    text-align: center;
}

.text-bigger {
    p {
        font-size: 1.25em;
        margin-bottom: 0;
    }
}

.sec-bg {
    background: $bg;
}

.display-flex {
    align-items: center;
    display: flex;
}

.white-bg {
    background-color: $white;
}

.blue-bg {
    background-color: $blue-hover;
}

.grey-bg {
    background-color: $bg;
}

.font-weight-normal {
    * {
        font-weight: normal;
    }
}

.width-xxl {
    .container {
        max-width: 103em;
    }
}

.width-xl {
    .container {
        max-width: 87.5em;
    }
}

.svg-57 img {
    max-width: 57em;
}

.px-desktop-mobile {
    padding-bottom: 8.75em;
    padding-top: 8.75em;

    @media (max-width: 48em) {
        padding-bottom: 0;
        padding-top: 4.75em;
    }
}

.performance {
    .row {
        padding: 5.6em 3.1em 0;

        img {
            max-height: 5.9em;
        }
    }
}

.img-60 {
    display: block;
    margin: auto;
    max-width: 60%;
}

@media (min-width: 992px) {
    .py-lg-8em {
        margin: 11em 0;
    }

    .mw-lg-28em {
        max-width: 28em;
    }
}

.mw-16em-p {
    p {
        margin: auto;
        max-width: 16em;
    }
}

.news-info {
    background-color: $news-info;
    display: inline-block;
    padding: 0.25em 0.8em 0.1em;
    position: relative;

    >* {
        line-height: 1;
        margin: 0;
        padding: 0;
    }

    &::after {
        border-right: 6px solid transparent;
        border-top: 6px solid $news-info;
        bottom: -6px;
        content: '';
        height: 0;
        left: 0;
        position: absolute;
        width: 0;
    }
}

.center-box {
    @media (max-width: 480px) {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-left: 2em;
    }
}

.text-decoration-none {
    text-decoration: none;
}
