//
//	MAIN - VARIABLES
// ----------------------------------------------------

// Font weight
$bold: 900;

// Space
$space-sm: 25px;
$space-md: 50px;
$space-lg: 100px;

// Colors
$dark-grey: #262626;
$header-text: #d9d9d9;
$grey-menu: #595959;
$blue-hover: #00a2e2;
$red: #d73577;
$bg: #f7f7f7;
$btn-bg: #a0a0a0;
$grey: #808080;
$success: #32c518;
$warning: #f6d128;
$debug: #ccc;
$info: #cab999;
$error: #bd122e;
$white: #fff;
$dark-black: #212529;
$table-border: #c8c8c8;
$blue-light-hover: #00adf2;
$btn-hover: #0088c0;
$btn-border: #0073a3;
$blackfullytransparent: rgba(0, 0, 0, 0);
$news-info: #ffe300;
$highlighted: #ca1e2f;
