@use '../variables' as *;

//
//	FOOTER STYLES
// ----------------------------------------------------
footer {
    background-color: $dark-grey;
    color: $header-text;
    margin: 0;
}

hr {
    background: $header-text;
}

.site-map {
    color: $header-text;
    padding: 65px 0 0;

    &__item {
        h2 {
            font-size: 16px;
            font-weight: $bold;
            line-height: 24px;
            margin-bottom: 32px;
            text-transform: uppercase;
        }

        p {
            font-size: 16px;
            line-height: 24px;
            margin: 0;

            a {
                color: $header-text;
                font-weight: normal;
                text-decoration: none;
                transition: all 0.2s ease-in;

                &:hover {
                    font-weight: $bold;
                    transition: all 0.2s ease-in;
                }
            }
        }
    }
}

.footer-logo {
    img {
        width: 126px;
    }
}

.turris-is-developed {
    font-size: 14px;
    line-height: 24px;

    p {
        margin: 0;
    }
}

.footer-down-block {
    padding: 18px 0 32px;
}
