@use '../variables' as *;

//
//	ARTICLE - DETAIL ARTICLE
// ----------------------------------------------------
.aldryn-newsblog-detail {
    .aldryn-newsblog-header {
        display: flex;
        margin: 0 auto;
        padding: 6em 0 2.2em;

        @media (max-width: 55em) {
            flex-direction: column;
            padding: 2.2em 0;
        }

        &:hover {
            background-color: $bg;
            cursor: auto;
        }

        &:hover h2 {
            color: $dark-black;
        }

        h2 {
            font-size: 2.5em;
            font-weight: bold;
            margin: 0;
        }

        > div {
            margin-left: 10em;

            @media (max-width: 55em) {
                margin-left: 0;
            }
        }

        .date {
            color: $grey-menu;
            font-weight: bold;
            text-transform: uppercase;
        }

        .tag-arrow {
            margin-top: 0.75em;

            @media (max-width: 55em) {
                margin-bottom: 0.75em;
            }

            img {
                height: 1.4em;

                @media (max-width: 55em) {
                    height: 1em;
                }
            }
        }
    }

    .lead {
        background: $white;
        font-family: "Roboto", sans-serif;
        font-size: 20px;
        font-weight: 300;
        line-height: 32px;
        padding: 32px;

        img {
            display: block;
            margin-left: auto;
            margin-right: auto;
            max-height: 560px;
            max-width: 92%;

            &.fullwidth {
                max-width: 100%;
            }

            &.left {
                float: left;
                max-height: 400px;
                max-width: 45%;
                padding: 0 32px 32px 0;
            }

            &.right {
                float: right;
                max-height: 400px;
                max-width: 45%;
                padding: 0 0 32px 32px;
            }
        }
    }
}

@media (max-width: 1024px) {
    .aldryn-newsblog-detail {
        .lead {
            img {
                max-width: 100%;

                &.left,
                &.right {
                    float: none;
                    max-width: 100%;
                    padding: 0;
                }
            }
        }
    }
}

.artic-all {
    background: $bg;
}

html[lang="en"] .articles-all .lead .news-footer a {
    &::after {
        left: 6em;
    }

    &:hover::after {
        left: 90%;
    }
}
