//
//	SECTION - COLLASE - TRUE
// ------------------------------------------------------
[data-bs-toggle='collapse'][aria-expanded='true'] {
    cursor: pointer;
    padding: 1em 0;

    > * {
        position: relative;
        width: calc(100% - 40px);

        * {
            margin: 0;
        }

        &::after {
            background: url('../img/icon-plus.svg') center center no-repeat;
            content: '';
            display: block;
            height: 40px;
            position: absolute;
            right: -40px;
            top: 10px;
            transform: rotate(45deg);
            transition: all 0.2s ease-in-out;
            width: 40px;

            @media (max-width: 48em) {
                top: 0;
            }
        }
    }

    &.one-line {
        align-items: center;
        display: flex;
        min-height: 69px;

        > *::after {
            top: -2px;
        }
    }
}
