// --------------------------------------------------
//  defaultflex - $align-items, $justify-content
// --------------------------------------------------
@mixin defaultflex($align-items: center, $justify-content: space-between) {
    align-items: $align-items;
    display: flex;
    justify-content: $justify-content;
}

@mixin arrow() {
    background-image: url('../img/arrow-light.svg');
    content: '';
    display: inline-block;
    height: 18px;
    transition: all 0.2s ease-in;
    width: 20px;
}
