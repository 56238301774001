@use '../variables' as *;

//
//	MENU - MAIN MENU
// ------------------------------------------------------

.main-menu {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    text-align: left;

    @media (min-width: 992px) {
        &.three-level-menu {
            min-height: 193px;
        }
    }

    > li {
        display: block;
        font-size: 14px;
        list-style-type: none;
        margin-left: 32px;
        text-transform: uppercase;
        transition: all 0.2s ease-in;

        @media (min-width: 992px) {
            display: inline-block;
        }

        > a {
            color: $header-text;
            display: block;
            padding: 30px 0 10px;
            text-decoration: none;

            @media (min-width: 992px) {
                padding: 25px 0;
            }
        }

        &.active {
            font-weight: $bold;

            @media (min-width: 992px) {
                > a {
                    box-shadow: inset 1px -8px 0 -2px $grey-menu;
                }
            }

            .menu-wrapper {
                display: block;
            }

            .active .menu-underwrapper {
                display: block;
            }
        }

        @media (min-width: 992px) {
            &:hover {
                box-shadow: inset 1px -3px 0 -2px $blue-hover;
                cursor: pointer;
                transition: all 0.2s ease-in;
            }
        }

        &.last {
            margin-bottom: 48px;

            @media (min-width: 992px) {
                margin-bottom: 0;
            }
        }
    }
}

.menu-wrapper {
    display: block;

    @media (min-width: 992px) {
        display: none;
        height: 61px;
    }
}

.menu-underwrapper {
    background-color: $grey-menu;
    display: block;

    @media (min-width: 992px) {
        display: none;

        &.active {
            display: block;
        }
    }
}

.menu-btn {
    background-image: url('../img/menu-dark.svg');
    cursor: pointer;
    display: inline-block;
    float: left;
    height: 20px;
    margin: 1.5em 0;
    padding: 10px 0;
    width: 20px;
}

.show-nav {
    .menu-btn {
        background-image: url('../img/menu-light.svg');
    }
}
