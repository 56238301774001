@use '../variables' as *;
@use '../mixins';

//
//	ARTICLE - BASE STYLE FOR PAGE WITH ALL ARTICLES
// ------------------------------------------------------
.container {
    &--article {
        max-width: 1025px;

        .aldryn-newsblog {
            width: 100%;
        }
    }
}

article {
    div.main-content {
        &.no-title {
            padding-top: 1em;
        }
    }
}

.latest-title {
    margin: 0 auto;
    padding: 6em 0 2.2em;
    width: 60%;

    div {
        color: $grey-menu;
        font-size: 1em;
        font-weight: bold;
        text-transform: uppercase;
    }

    a {
        text-decoration: none;

        h1 {
            color: $dark-grey;
            font-weight: bold;
            transition: all 0.1s ease-in;

            &:hover {
                color: $blue-hover;
            }
        }
    }
}

.articles-all {
    article {
        background-color: $bg;
        margin: 0;
        padding: 2em 0 6em;

        div.main-content {
            background: $white;
        }
    }

    .category {
        margin: 0;
    }

    .aldryn-newsblog-article {
        background: $white;
        border-radius: 4px;
        height: 425px;
        margin-bottom: 2em;
        overflow: hidden;
        padding: 0;
        position: relative;
        transition: all 0.2s ease-in;

        .image {
            display: none;
        }

        .author {
            display: none;
        }

        > a {
            color: $dark-grey;
            text-decoration: none;
            transition: all 0.2s ease-in;
        }

        h2 {
            font-size: 1.6em;
            font-weight: bold;
            line-height: 1.3em;
            margin: 0;
            max-height: 5.6em;
            overflow: hidden;
            padding: 10px 16px 0;
        }

        .aldryn-newsblog-meta {
            color: $dark-grey;
            margin-top: 0;
            padding: 0.3em 1em 1em;
            transition: all 0.1s ease-in;

            p {
                margin: 0;
            }
        }
    }

    .news-footer {
        background: $white;
        bottom: 0;
        left: 0;
        position: absolute;
        width: 100%;

        a {
            border-top: 1px solid $header-text;
            color: $grey-menu;
            display: block;
            font-weight: bold;
            padding: 1em;
            text-decoration: none;
            text-transform: uppercase;
            transition: all 0.2s ease-in;

            &::after {
                @include mixins.arrow();

                left: 3em;
                position: absolute;
            }

            &:hover {
                color: $blue-hover;
                transition: all 0.2s ease-in;

                &::after {
                    left: 90%;
                    transition: all 0.2s ease-in;
                }
            }
        }
    }

    .aldryn-newsblog-header {
        transition: all 0.2s ease-in;

        &:hover {
            background: $blue-hover;
            border-radius: 4px 4px 0 0;
            cursor: pointer;
            transition: all 0.2s ease-in;

            h2 {
                color: $white;
                transition: all 0.2s ease-in;

                a {
                    color: $white;
                    transition: all 0.2s ease-in;
                }
            }

            .aldryn-newsblog-meta {
                color: $white;
                transition: all 0.2s ease-in;
            }
        }
    }

    .lead {
        border-top: 1px solid $header-text;
        cursor: default;
        font-size: 1em;
        overflow: hidden;
        padding: 1em;

        .main-text {
            margin-bottom: 1em;
            overflow: hidden;

            @for $i from 1 through 6 {
                h#{$i} {
                    font-weight: $bold;
                    margin-bottom: 1em;
                    padding: 0;

                    @if $i < 4 {
                        font-size: 1em + ((4 - $i) * 0.1);
                    } @else {
                        font-size: 1em;
                    }
                }
            }

            img {
                display: none;
            }
        }
    }

    .more-info {
        position: relative;

        &::before {
            background: linear-gradient(to bottom, rgba(255, 255, 255, 0.01) 0%, rgba(255, 255, 255, 1) 100%);
            content: ' ';
            display: block;
            height: 2em;
            line-height: 1.5em;
            opacity: 0.99;
            position: absolute;
            top: -2em;
            width: 100%;
        }
    }
}
