@use '../mixins';

//
//	SECTION - BUY TURRIS
// ------------------------------------------------------

.buy-turris {
    img {
        max-width: 100%;
    }

    .display-flex {
        @include mixins.defaultflex(center, space-between);

        @media (max-width: 991px) {
            flex-wrap: wrap;
        }

        >a {
            text-align: center;

            @media (max-width: 991px) {
                margin-bottom: 4.5em;
                width: 50%;
            }

            @media (max-width: 768px) {
                width: 100%;
            }
        }

        img {
            object-fit: contain;
        }
    }

    .row {
        padding: 0;
    }

    figure {
        margin-bottom: 0;
    }

    .display-flex.three-on-row,
    .display-flex.four-on-row {
        @include mixins.defaultflex(center, center);

        flex-wrap: wrap;

        >a {
            margin-bottom: 3em;
            padding: 0 1em;
            text-align: center;
            width: 25%;

            @media (max-width: 991px) {
                max-width: 200px;
                width: 50%;
            }

            @media (max-width: 768px) {
                width: 100%;
            }
        }

        img {
            object-fit: contain;
        }
    }

    .display-flex.three-on-row {
        @media (min-width: 991px) {
            >a {
                width: 33.3333%;
            }
        }

        @media (max-width: 991px) {
            >a {
                max-width: 300px;
            }
        }
    }
}
