@use '../mixins';

//
//	SECTION - HP - PERFORMANCE - TURRIS, OMNIA
// ------------------------------------------------------
.hp-performance {
    height: 50vh;

    @media (max-width: 55em) {
        height: auto;
    }

    .row {
        height: 100%;
    }

    .hp-text {
        margin: 0 0 11vh 6vh;
        z-index: 99;

        @media (max-width: 47.9375em) {
            margin: 0 0 0 1em;
        }
    }

    .col-lg-6 {
        @include mixins.defaultflex(flex-end, space-between);

        height: 100%;
        overflow: hidden;
        position: relative;

        @media (max-width: 55em) {
            height: 50vh;
        }

        @media (max-width: 47.9375em) {
            @include mixins.defaultflex(flex-end, center);

            height: auto;
        }

        @media (max-width: 30em) {
            align-items: center;
            flex-direction: column-reverse;
        }

        &:first-of-type {
            @media (max-width: 47.9375em) {
                margin-bottom: 1.5em;
            }

            .hp-text {
                @media (max-width: 47.9375em) {
                    transform: translate(0, -1.5em);
                }
            }

            >img {
                @media (max-width: 47.9375em) {
                    transform: translate(0, -1.5em);
                }

                @media (max-width: 30em) {
                    transform: translate(10%, -1.5em);
                }
            }

            figure img {
                @media (max-width: 47.9375em) {
                    bottom: 30px;
                }

                @media (max-width: 30em) {
                    bottom: 11em;
                    right: 3.5em;
                }
            }
        }

        &:last-of-type {
            @media (max-width: 47.9375em) {
                margin-bottom: 3em;
            }
        }
    }

    h1 {
        margin-bottom: 0;
        width: 150%;
    }

    figure {
        margin: 0;
    }

    .bg-img {
        bottom: 0;
        object-fit: contain;
        position: absolute;
        right: 0;
        top: -9.7vh;
        width: 70%;

        @media (max-width: 88em) {
            width: 65%;
        }

        @media (max-width: 81em) {
            width: 60%;
        }

        @media (max-width: 55em) {
            height: 45vh;
        }

        @media (max-width: 47.9375em) {
            height: auto;
            position: relative;
            top: 0;
        }

        @media (max-width: 30em) {
            margin-bottom: 2.5em;
            width: 100%;
        }
    }

    .col-lg-6:last-of-type .bg-img {
        top: 0;

        @media (max-width: 47.9375em) {
            top: 0;
        }
    }

    .bg-img-desktop {
        display: block;

        @media (max-width: 890px) {
            display: none;
        }
    }

    .bg-img-mobile {
        display: none;

        @media (max-width: 889px) {
            display: block;
        }
    }
}

/* Portrait - for tablet */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (min-device-pixel-ratio: 2) and (orientation: portrait) {
    .hp-performance {
        .col-lg-6 {
            .bg-img {
                top: 0;
            }

            &:last-of-type .bg-img {
                top: 0;
            }
        }
    }
}

/* Landscape - for tablet */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (min-device-pixel-ratio: 2) and (orientation: landscape) {
    .hp-performance {
        .col-lg-6 {
            &:last-of-type .bg-img {
                top: 0;
            }
        }
    }
}

@media only screen and (min-width: 881px) and (max-width: 1023px) and (min-height: 900px) {
    .hp-performance {
        .bg-img {
            margin-top: 5vh;
            top: 0;
        }

        .col-lg-6:last-of-type .bg-img {
            top: 0;
        }
    }
}
