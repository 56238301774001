@use '../variables' as *;
@use '../mixins';

//
//	ARTICLE - DETAIL ARTICLE - FOOTER
// ----------------------------------------------------
.socials {
    align-items: center;
    display: flex;

    p {
        color: $grey-menu;
        display: inline;
        font-weight: bold;
        margin: 0;
        margin-right: 1em;
        text-transform: uppercase;
    }

    a {
        display: inline-block;
        height: 24px;
        margin-right: 0.5em;
        text-decoration: none;
        transition: all 0.1s ease-in;
        width: 24px;

        &:hover {
            transition: all 0.1s ease-in;
        }

        &.twitter {
            background-image: url('../img/twitter-dark.svg');

            &:hover {
                background-image: url('../img/twitter-hover.svg');
            }
        }

        &.linkedin {
            background-image: url('../img/linkedin-dark.svg');

            &:hover {
                background-image: url('../img/linkedin-hover.svg');
            }
        }

        &.facebook {
            background-image: url('../img/facebook-dark.svg');

            &:hover {
                background-image: url('../img/facebook-hover.svg');
            }
        }
    }
}

.newsblog-footer {
    background: $white;
    padding: 1em 2em 2em;

    .footer-wrapper {
        align-items: center;
        border-top: 1px solid $header-text;
        display: flex;
        justify-content: space-between;
        padding: 2em 0 0;
        width: 100%;
    }

    .aldryn-newsblog-pager {
        a {
            color: $grey-menu;
            display: inline;
            font-weight: bold;
            margin: 0;
            text-decoration: none;
            text-transform: uppercase;
            transition: all 0.1s ease-in;

            &:hover {
                color: $blue-hover;
                transition: all 0.1s ease-in;
            }

            &::after {
                @include mixins.arrow();
            }
        }
    }
}
