@use '../variables' as *;

.container-table {
    table {
        tbody {
            line-height: 1.2;

            th {
                background: $bg;
                border: 1px solid $bg;
                border-bottom: 0;
                border-top: 1px solid $dark-black;
                padding: 0.45em;
                text-align: left;
                vertical-align: inherit;

                @media (max-width: 48em) {
                    height: 5em;
                    line-height: 1.2;
                    padding: 0 0.66em;
                }
            }

            tr:last-of-type th {
                border-bottom: 1px solid $dark-black;
            }
        }
    }
}

.table-swipe {
    thead tr th:first-of-type img {
        display: none;
    }
}

@media (max-width: 25em) {
    .table-swipe thead tr th:first-of-type {
        position: relative;

        img {
            bottom: 0;
            display: block;
            left: -50px;
            margin: auto;
            padding: 0;
            position: absolute;
            right: 0;
            top: 0;
        }
    }
}
