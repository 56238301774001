@use '../variables' as *;

//
// SECTION - HP - PERFORMANCE -> BORDER PRODUCTS
// ------------------------------------------------------
.border-products {
    .col-lg-6 {
        @media (min-width: 48em) {
            border-right: 2px solid $bg;
            border-top: 2px solid $bg;
            padding: 0;
        }

        &:first-of-type {
            >img {
                @media (max-width: 47.9375em) {
                    transform: translate(0, -0.2em);
                }

                @media (max-width: 30em) {
                    transform: translate(10%, -0.2em);
                }
            }
        }

        &:last-of-type {
            @media (max-width: 47.9375em) {
                margin-bottom: 3em;
            }

            @media (min-width: 48em) {
                border-right: 0;
            }
        }
    }
}
