@use '../variables' as *;

//
//	PAGE - SEARCH RESULT
// ------------------------------------------------------
.container--search {
    padding-bottom: 4em;
    padding-top: 4em;
}

.pagenav {
    text-align: center;

    ul {
        padding: 0;

        li {
            display: inline-block;
            list-style: none;
            padding-right: 1em;

            a {
                color: $blue-hover;
            }
        }
    }
}

.results-list {
    padding: 0;

    span.highlighted {
        color: $highlighted;
        font-weight: bold;
    }

    ul {
        li {
            list-style: none;
            margin-bottom: 2em;

            p {
                a {
                    color: $blue-hover;
                }
            }

            h3 {
                font-weight: $bold;

                a {
                    color: $dark-grey;

                    &:hover {
                        color: $dark-grey;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}
