//
//	PAGE - SUPPORT
// ------------------------------------------------------
.support {
    max-width: 1280px;
    padding: 2em 0 5em;

    .row {
        padding: 0 0 3em;

        @media (max-width: 768px) {
            padding: 0;
        }

        > div {
            max-width: 31em;

            @media (max-width: 991px) {
                padding-right: 8%;
            }

            @media (max-width: 768px) {
                max-width: 100%;
                padding: 4em;
            }
        }

        p {
            font-size: 130%;
            font-weight: 300;
        }
    }

    hr {
        margin: 0 auto 1em;
        max-width: 84%;
    }
}
