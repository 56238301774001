@use '../variables' as *;

//
//	ARTICLE - ALL ARTICLES - MORE INFO
// ----------------------------------------------------
.more-info {
    a.btn-link {
        background: $blue-hover;
        border: 1px solid $btn-border;
        color: $white;
        font-weight: 700;
        padding: 0.5em 1em;
        text-decoration: none;
        text-transform: uppercase;
        transition: all 0.15s;

        &:hover {
            background-color: $btn-hover;
        }

        .arrow {
            background-image: url('../img/arrow-white.svg');
        }
    }
}
