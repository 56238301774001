@use '../variables' as *;

//
//	SECTION - INTRO
// ------------------------------------------------------
.intro {
    &__img {
        img {
            float: right;
            width: 100%;
        }
    }

    &__text {
        align-content: center;
        display: flex;
        flex-wrap: inherit;

        &__subtitle {
            font-size: 1.25em;
            font-weight: 300;
            line-height: 1.8em;
            margin: 0;
        }

        &__link {
            margin: 2.3em 0 0;

            a {
                color: $red;
                font-size: 1em;
                font-weight: $bold;
                line-height: 1.5em;
                text-decoration: none;
                text-transform: uppercase;

                &:hover {
                    color: $blue-hover;

                    .arrow {
                        background-image: url("../img/arrow-hover.svg");
                    }
                }
            }

            img {
                display: inline-block;
                position: relative;
                top: -2px;
            }
        }

        &__title {
            h1 {
                margin: 0;
            }
        }
    }
}
