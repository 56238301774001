@use '../variables' as *;
@use '../mixins';

//
//	SECTION - RATING
// ------------------------------------------------------
.rating {
    background-color: $bg;
    padding-bottom: 2.75em;
    padding-top: 4.75em;

    h3 {
        padding-bottom: 2.4em;
    }

    .img-center {
        @include mixins.defaultflex(center, center);

        height: 100px;

        img {
            display: block;
            margin: auto auto 1em;
            max-height: 80px;
            max-width: 160px;
        }
    }

    .col-lg-4 {
        display: inline-flex;
        padding: 0 1.5em 3em;

        @media (max-width: 991px) {
            padding: 0 1.5em 5em;
        }
    }

    .rating-content {
        @include mixins.defaultflex(flex-start, space-between);

        background-color: $white;
        flex-direction: column;
        padding: 2.5em 2.5em 1.2em;
    }

    .rating-header {
        margin-bottom: 50px;
    }

    .rating-footer {
        font-size: 0.85em;
        height: 6.5em;
        line-height: 1.2;

        strong {
            font-size: 1.2em;
        }
    }
}
