//
// FONT ROBOTO
// ------------------------------------------------------
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 300;
    src: url('../fonts/Roboto-LightItalic.ttf');
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/Roboto-Light.ttf');
}

@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    src: url('../fonts/Roboto-Italic.ttf');
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/Roboto-Regular.ttf');
}

@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 500;
    src: url('../fonts/Roboto-MediumItalic.ttf');
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/Roboto-Medium.ttf');
}

@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 700;
    src: url('../fonts/Roboto-BoldItalic.ttf');
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/Roboto-Bold.ttf');
}

@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 900;
    src: url('../fonts/Roboto-BlackItalic.ttf');
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    src: url('../fonts/Roboto-Black.ttf');
}
