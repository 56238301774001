@use '../variables' as *;

//
//	SECTION - IMAGES LIKE BACKGROUND, OVER THE FULL WIDTH
// ------------------------------------------------------
.img-bg {
    color: $white;
    padding-bottom: 17em;
    padding-top: 4.75em;
    position: relative;

    @media (min-width: 130em) {
        margin-bottom: 4em;
        overflow: hidden;
    }

    @media (max-width: 112.5em) {
        margin-bottom: 6em;
        padding-bottom: 9em;
    }

    @media (max-width: 48em) {
        background: $dark-black;
        padding-bottom: 0;
    }

    .img-for-background {
        display: block;
        left: 0;
        margin: auto;
        padding: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100vw;

        @media (max-width: 112.5em) {
            height: 100%;
            object-fit: cover;
        }

        @media (max-width: 48em) {
            display: none;
        }
    }

    p {
        font-size: 1.56em;
    }

    .row {
        padding: 0 3.2em;

        img {
            display: block;
            height: 5em;
            margin: auto;
        }

        .col-lg-2 {
            margin-bottom: 3em;
        }

        .col-sm-6 {
            @media (max-width: 36em) {
                flex: 0 0 50%;
                max-width: 50%;
            }
        }
    }

    h3 {
        font-size: 1.8em;
        margin-bottom: 1.5rem;
    }

    a {
        color: $blue-hover;
        display: flex;
        font-size: 1.56em;
        justify-content: center;
        margin: auto;
        padding: 2.4em 0;
        text-align: center;
        width: 12.5em;

        @media (max-width: 36em) {
            font-size: 1.3em;
            max-width: 100%;
            text-align: left;
        }

        &::before {
            background: url('../img/icon-play.svg') center center no-repeat;
            content: '';
            display: inline-block;
            height: 40px;
            margin-right: 0.5em;
            position: relative;
            top: -2px;
            width: 40px;
        }
    }
}
