//
//	RESPONSE - max-width: 350px
// ----------------------------------------------------
@media (max-width: 350px) {
    .carousel__img img.center-block {
        max-height: 315px;
        max-width: 215px;
    }

    .carousel-control.right {
        top: 1px;
    }

    .carousel-control {
        top: 6px;
    }

    .parts-slider__txt .c-caption h5 {
        vertical-align: middle;
        width: 84%;
    }
}
