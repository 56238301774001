@use '../variables' as *;

//
//	SECTION - FLAG - REGIONAL DEALERS
// ------------------------------------------------------
.flag-container {
    padding-bottom: 5.75em;
    padding-top: 4.75em;

    @media (max-width: 72em) {
        padding-bottom: 0.75em;
    }

    img {
        width: 40px;
    }

    p {
        margin: 0;
        padding-left: 1em;
    }

    figure {
        margin-bottom: 0;
    }

    .col-lg-12 {
        p {
            margin: 0 0 4.75em;
        }
    }

    .row {
        .col-lg-2,
        .col-lg-4 {
            @media (max-width: 62em) {
                margin-bottom: 2.75em;
            }
        }
    }

    ul {
        margin: 1em 0 0;
        padding: 0;

        li {
            list-style-type: none;

            a {
                color: $dark-black;
                font-size: 0.95em;
                text-decoration: underline;
            }
        }
    }

    .container {
        max-width: 80em;
    }

    .container>.display-flex {
        align-items: flex-start;
        justify-content: space-between;

        @media (max-width: 991px) {
            flex-wrap: wrap;
        }

        >div {
            @media (max-width: 991px) {
                margin-bottom: 4em;
                width: 50%;
            }

            @media (max-width: 768px) {
                width: 100%;
            }
        }
    }
}
