/*
    Temporary style fixes after upgrade to the new Bootstrap 5.
*/
.stamp {
    position: relative;
    top: -14vh;

    img {
        bottom: auto;
    }
}

.img-bg > div {
    position: relative;
}

figure {
    text-align: center;
}

.float-start {
    // Reset original: float: left !important;
    float: none !important;
}
