@use '../variables' as *;

//
//	HEADER STYLES
// ----------------------------------------------------
header {
    background-color: $dark-grey;
    color: $header-text;
    margin: 0;
    position: relative;

    .container.wrapper {
        display: flex;
        justify-content: space-between;
    }

    .logo-icon {
        display: inline-block;
        padding: 21px 0 0;
        vertical-align: top;

        img {
            overflow: visible;
            position: relative;
            z-index: 10;
        }
    }

    a {
        text-decoration: none;
    }
}

.header {
    &__left-side {
        display: inline-block;

        .main-menu {
            .child.project {
                padding: 0 0 2em;
            }
        }
    }

    &__right-side {
        .main-menu {
            display: inline;
        }
    }
}

.language-chooser {
    display: inline-block;
    margin: 0;

    .lang {
        border-bottom: 2px solid transparent;
        display: inline-block;
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        line-height: 19px;
        list-style: none;
        margin-right: 32px;
        padding: 25px 0;
        text-transform: uppercase;
        transition: all 0.2s ease-in;

        a {
            color: $header-text;
            padding: 25px 0;
            text-decoration: none;
        }

        &.active {
            display: none;
        }

        &:hover {
            border-bottom: 2px solid $blue-hover;
            cursor: pointer;
            transition: all 0.2s ease-in;
        }
    }
}

.search {
    &-form {
        display: inline-block;
    }

    &-wrapper {
        background: $dark-grey;
        display: none;
        height: 52px;
        left: 0;
        position: absolute;
        text-align: center;
        width: 100%;
    }

    &-icon {
        position: relative;
        top: 3px;
    }

    &-input {
        background: $dark-grey;
        border: 0;
        caret-color: $blue-hover;
        color: $blue-hover;
        font-size: 1.6em;
        font-weight: $bold;
        position: relative;
        text-shadow: 0 0 0 $header-text;
        top: -2px;
        width: 50%;

        &::placeholder {
            color: $grey-menu;
        }
    }
}

#search {
    &-btn {
        display: none;
    }
}

#logo {
    &-dark {
        display: none;
    }
}

header.show-nav {
    nav {
        height: auto;
        max-height: 1000px;
    }

    #logo-dark {
        display: inline;
    }

    #logo-light {
        display: none;
    }
}
